@import "../../variables.scss";

.form-text {
  margin-top: 0;
  color: $mono-3;
  font-size: 0.875rem;
}

.secret-form {
	padding: 0;
	margin: 0;
	border: none;
	background: inherit;

	input[type=text] {
		display: none;
	}
}
