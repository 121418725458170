@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/utilities/spacing";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/media";
@import '../node_modules/@delta-defense/b2c-shared-react/components/toolTip/styles.css';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

@import "./variables.scss";

@font-face {
  font-family: "Stratum";
  src: url("https://www.usconcealedcarry.com/wp-content/themes/uscca_2016/fonts/Stratum/Stratum1WebBold/Stratum1WebBold.woff2")
      format("woff2"),
    url("https://www.usconcealedcarry.com/wp-content/themes/uscca_2016/fonts/Stratum/Stratum1WebBold/Stratum1WebBold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: $font-body;
  background-color: #121212;

  &:before {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    z-index: -1;
  }
}

h1 {
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

.main {
  padding-bottom: 65px;
}

.fixed-col {
  max-width: pxToRem(295);

  @media (min-width: $xs - 1px) {
    max-width: pxToRem(370);
  }
}

.card {
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 3px solid $border;
}

.uscca-logo {
  g {
    fill: white;
  }

  @media (max-width: $sm - 1px) {
    width: 80px;
    height: 84px;
  }
}

.btn {
  display: inline-block;
  line-height: 1.1;
  border: 1px solid transparent;
  font-family: $font-body;
  font-size: 1rem;
  font-weight: $bold;
  padding: pxToRem(11) pxToRem(28) pxToRem(12);
  text-align: center;
  transition: color 0.2s ease, background-color 0.2s ease,
  border-color 0.2s ease;
  user-select: none;
  vertical-align: middle;
  border-radius: 5px;
  margin: 0 0;
  letter-spacing: 0.05em;
  min-width: pxToRem(150);
  letter-spacing: 0;
  width: 100%;
  cursor: pointer;

  &-link {
    margin: 0;
    &:hover {
      text-decoration: underline;
    }
  }

  &-lg {
    font-size: 1.5rem;
    padding: pxToRem(15) pxToRem(32) pxToRem(17);
    min-width: pxToRem(250);
    max-width: pxToRem(350);

    @media (min-width: $sm) {
      min-width: pxToRem(300);
      max-width: pxToRem(450);
    }
  }

  &-sm {
    font-size: 1rem;
    font-weight: $bold;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    padding: pxToRem(6) pxToRem(20) pxToRem(7);
    min-width: 0;
    max-width: pxToRem(300);
  }

  &-primary {
    background: $prime;
    color: $white;
    border: 1px solid transparent;
    &:active {
      background: $prime !important;
      color: $mono !important;
      border: 1px solid transparent !important;
    }
    &:hover {
      background: #2f5ca8;
    }
  }

  &-alt {
    background: $mono;
    color: $white !important;
    &:hover {
      color: $prime !important;
    }
  }

  &-gold {
    background: $prime-5;
    color: $mono !important;
    &:hover {
      background: darken($prime-5, 10%) !important;
    }
  }

  &-plat {
    background: $mono-4;
    color: $white !important;
    &:hover {
      background: $mono-5 !important;
    }
  }

  &-elite {
    @extend .btn-primary;
  }

  &-default {
    background: transparent;
    color: $mono !important;
    border-color: $mono;
    &:hover {
      background-color: $prime !important;
    }
  }

  &-default-dark-bg {
    background: transparent;
    color: $white !important;
    border-color: $white;
    &:hover {
      background-color: $white !important;
      color: $mono !important;
    }
  }

  &-success {
    background: $success;
    color: $white !important;
    &:hover {
      background: $success-hover;
    }
  }

  &-warning {
    background: $warning;
    color: $white !important;
    &:hover {
      background: $warning-hover;
    }
  }

  &-disabled,
  &:disabled {
    background: $mono-3 !important;
    color: $white !important;
    pointer-events: none !important;
    border-color: transparent !important;
  }
}

.font-body {
  font-family: $font-body;
}
.font-title {
  font-family: $font-title;
}

.disabled {
  @extend .btn-disabled;
}

.hidden-frame {
  display: none;
  height: 0;
  width: 0;
  overflow: hidden;
}

// Prevents double clicking - needed due to third party controls used for this input
#signInRememberMeCheckBox {
  pointer-events: none;
}

.form-field__validate--error {
  font-size: 0.875rem;
}
