@import "../../variables.scss";

.form-control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin-top: 0.5rem;
}

.input--error {
  background-color: $warning;
  margin-top: 0.625rem;
  font-size: 0.875rem;
  padding: 0.625rem;
  width: 100%;
  color: $mono-1;
  border-radius: 5px;

  a {
    text-decoration: underline;
    color: $white;
  }
}

.nowrap {
  white-space: nowrap;
}

.description {
  color: $mono-3;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-top: 0;
}

.resetButton {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: $link;
  border: none;
  padding: 0;
  background: inherit;

  &:hover {
    text-decoration: underline;
    color: #2b4c5d;
  }

  svg {
    height: 11px;
    width: 11px !important;
    margin-right: 4px;
  }
}

.remember-section {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.remember-label {
  color: #9ea1a3;
  margin-top: 1.5rem;

  input {
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    margin-left: 0;
  }
}

.hidden-form-input {
  opacity: 0;
  position: absolute;
  padding: 0;
  margin: 0;
}
