@import '../../variables.scss';

.logout__divider {
	height: 0;
	max-width: 22rem;
	width: calc(100% - 4rem);
	margin: 1.5rem auto;
	border: 0;
	border-top: 1px solid black;
}

.logout__message {
	text-align: center;
	font-size: pxToRem(20);

	@media (min-width: $sm) {
		font-size: pxToRem(24);
	}
}
