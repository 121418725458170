@import "../../variables.scss";

.sign-in-links {
  color: $link;

  a {
    font-size: 0.9375rem;
    font-weight: 600;
    color: $link;
    text-decoration: none;

    &:hover {
      color: $link-dark;
    }
  }
}
