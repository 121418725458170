@import "../../variables.scss";

.form-field {
  $root: &;

  position: relative;
  margin-top: 1.75rem;

  .mr {
    margin-right: 1.75rem;
  }

  &.mt-lg {
    margin-top: 2rem;
  }

  &.mb-lg {
    margin-bottom: 2rem;
  }

  &.mb-sm {
    margin-bottom: 1.25rem;
  }

  &__label span {
    position: absolute;
    left: 0.8rem;
    top: 0.7rem;
    color: #747678;
    font-size: 1rem;
    font-weight: 500;

    transition: color 0.3s ease 0s, font-size 0.3s ease 0s, top 0.3s ease 0s;
    pointer-events: none;

    &.optional-label {
      left: 0.8rem;
      top: 2.5rem;
    }
  }

  &__input,
  &__select {
    width: 100%;
    background: #fff !important;
    border-radius: 5px;
    height: 2.75rem;
    border: 0;
    color: transparent;
    font-family: inherit;

    &:focus,
    &:disabled:not([value=""]) {
      outline: none;
    }
  }

  &__input {
    padding: 1.5rem 0.81rem 0.5rem;
    font-size: 1rem;
    transition: top 0.3s ease 0s, border-size 0.3s ease 0s, border-color 0.3s ease 0s;

    &:focus,
    &:not([value=""]) {
      color: #747678;

      + span {
        top: 0.25rem;

        color: #747678;
        font-size: 0.75rem;
        letter-spacing: 0.25px;

        &.optional-label {
          top: 2.18rem;
        }
      }
    }
  }

  &__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: 1rem;
    color: #646464;
    font-size: 1.125rem;
    font-weight: 500;

    &-wrap {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 1rem;
        top: calc(50% - 0.25rem);

        width: 0;
        height: 0;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-top: 0.5rem solid #646464;
      }
    }

    &:hover {
      cursor: pointer;
      background: #f2f2f2 !important;
    }
  }

  &__validate {
    position: absolute;
    top: calc(100% + 10px);
    font-size: 1rem;
    padding: 0.625rem;
    width: 100%;
    color: $mono-1;
    border-radius: 5px;
    display: block;

    &--error {
      background-color: $warning;
    }
  }

  &.invalid {
    margin-bottom: 4rem;

    .form-field__input {
      border-color: $warning !important;
    }
  }

  &__svg-icon {
    position: absolute;
    right: 0.68rem;
    top: 0.75rem;

    &--error {
      color: $warning;
    }

    &--optional {
      top: 2.625rem;
    }

    &--password {
      right: 2.125rem;
    }
  }

  &__password {
    &:hover {
      cursor: pointer;
    }
  }

  &__optional {
    color: $mono-4;
    font-size: 0.875rem;
  }

  button[class^="container"] {
    padding-top: 0;
    padding-bottom: 0;
    color: $mono-4;
    overflow: visible;
    display: block;

    &:hover {
      color: $border;
    }
  }

  div[class^="popup"] {
    width: 12.375rem;
    max-width: none;
    height: auto;
    top: -1rem;
    right: calc(100% + 4px);
    left: inherit;

    @media (min-width: $md) {
      width: 14.375rem;
      left: calc(100% + 4px);
      right: inherit;
    }

    p {
      white-space: normal;
      font-weight: 600;
      font-family: $font-body;
    }
  }

  div[class^="arrow"] {
    top: 1.375rem;
    right: -0.5rem;
    left: inherit;
    border-right: 0;
    border-left: 8px solid #386cc6;

    @media (min-width: $md) {
      right: inherit;
      left: -0.5rem;
      border-right: 8px solid #386cc6;
      border-left: 0;
    }
  }
}
