@keyframes spinning {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.spinner {
	display: block;
	margin: 2rem auto;
	animation: spinning 1s linear infinite;
}
