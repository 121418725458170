@import "../../variables.scss";

.auth-title {
  color: $mono-2;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 1.6;
  margin-top: 0;
  text-align: center;
}

.auth-title--center {
  text-align: center;
}
