@import "../../variables.scss";

.auth-alert__a {
  color: $link;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #2b4c5d;
  }
}
