@import "../../variables.scss";

.footer {
  font-size: 0.740rem;
  color: #9ea1a3;
  padding: 0px calc(-200px + 50vw);

  @media (min-width: $md) {
    padding: 0px calc(-400px + 50vw);
    max-width: unset;

    br {
      display: none;
    }

    .address {
      margin-top: 4px !important;
    }
  }
}

.footer a {
  font-weight: 600;
  color: $mono-3;
  text-decoration: none;
  text-decoration: underline;

  &:hover {
    color: $white;
  }
}
