@import "../../variables.scss";

.container {
  .backButton {
      background-color: transparent;
      border: none;
      display: flex;
      padding: 0px;
      .back {
        color: white;
        font: normal normal normal pxToRem(16)/pxToRem(25) $font-body;
        margin-left: 6px;
      }
    }

  h1 {
      color: white;
      text-align: center;
      text-transform: none;
    }

  p {
    color: white;
    font: normal normal normal pxToRem(16)/pxToRem(25) $font-body;
    .nobr {
      white-space: nowrap;
      .phoneLink {
          color: white !important;
          text-decoration: underline;
          &:hover {
            color: rgb(40, 40, 253) !important;
          }
        }
    }
  }


  .svg-wrap {
    display: flex;
    justify-content: center;
    svg {
      width: 90px;
      height: 90px;
      fill: white;
    }
  }
}
